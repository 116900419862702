.appointment-scheduler {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.input-group {
    margin: 10px;
    width: 300px;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input[type='date'] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}

.confirmation {
    margin-top: 15px;
    font-weight: bold;
}
